import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'RebatiFitness';

  constructor(private translateService: TranslateService, private renderer: Renderer2) {}
  ngOnInit(): void {
    this.translateService.setDefaultLang('fa');

    if(window?.Telegram?.WebApp?.colorScheme === 'dark') {
      window?.Telegram?.WebApp?.setHeaderColor('#000000');
      this.renderer.addClass(document.body, 'dark');
    }else {
      window?.Telegram?.WebApp?.setHeaderColor('#f3f4f6');
      this.renderer.removeClass(document.body, 'dark');
    }

    window?.Telegram?.WebApp.onEvent('themeChanged', (event: any) => {
      if(event.colorScheme === 'dark') {
        this.renderer.addClass(document.body, 'dark');
        window?.Telegram?.WebApp?.setHeaderColor('#000000');

      }else {
        this.renderer.removeClass(document.body, 'dark');
        window?.Telegram?.WebApp?.setHeaderColor('#f3f4f6');
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
    window?.Telegram?.WebApp.ready();
    }, 500);
  }
}
